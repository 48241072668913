import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/Episode292023HorrorRoundup.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the Bring Me The Axe 2023 horror movie roundup"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 29</h1>
                    <h2>2023 Roundup</h2>
                    <h3>December 18, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/29-2023-Horror-Movie-Roundup-e2dd450/a-aaol3su" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe episode 29, 2023 Horror Movie Roundup"></iframe>
                        </div>
                        <p><Link to="/transcripts/2023-roundup">Read the episode transcript</Link></p>
                        <p>Bring Me the Axe is a comedy podcast celebrating the best (and worst) horror from a time when the video store ruled the night. Every other week, brothers Bryan and Dave White (and the occasional guest) heed the call of nostalgia and evaluate the classic 70s and 80s horror movies they loved in their childhood to determine whether the movies are still relevant today or should be allowed to fade into obscurity.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/black-christmas">Next Episode</Link></li>
                        <li><Link to="/episodes/silent-night-deadly-night">Next Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)